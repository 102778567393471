@use "../styles/theme.scss";

:local(.radio-input) {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 8px 0;
}

:local(.option) {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  margin-left: 16px;
  cursor: pointer;
  user-select: none;
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }
}

:local(.input) {
  width: 24px;
  height: 24px;
  border-radius: theme.$border-radius-full-roundness;
  border: 1px solid theme.$radio-border-color;
  background-color: theme.$radio-bg-color;
  align-self: center;
  position: relative;
  appearance: none;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;

  &::after {
    content: " ";
    position: absolute;
    background-color: transparent;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    width: 16px;
    height: 16px;
    border-radius: theme.$border-radius-xl;
  }

  &:hover::after {
    background-color: theme.$radio-bg-color-hover;
  }

  &:active::after {
    background-color: theme.$radio-bg-color-pressed;
  }

  &:checked::after {
    background-color: theme.$active-color;
  }

  &:checked:hover::after {
    background-color: theme.$active-color-hover;
  }

  &:checked:active::after {
    background-color: theme.$active-color-pressed;
  }
}

:local(.content) {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  color: theme.$text2-color;
}

:local(.label) {
  font-size: theme.$font-size-sm;
  font-weight: theme.$font-weight-bold;
  color: theme.$text1-color;
}

:local(.description) {
  margin-top: 4px;
  font-size: theme.$font-size-xs;
  font-weight: theme.$font-weight-regular;
  color: theme.$text2-color;
}
