@use "../styles/theme.scss";

:local(.lg) {
  @media(min-width: theme.$breakpoint-lg) and (min-height: theme.$breakpoint-vr) {
    svg {
      height: 20px;
      width: 20px;
    }
  }
}

:local(.closeButton) {
  svg {
    color: theme.$close-icon-button-color;
  }

  &:hover {
    color: theme.$close-icon-button-color-hover;

    svg {
      color: theme.$close-icon-button-color-hover;
    }
  }

  &:active {
    color: theme.$close-icon-button-color-pressed;

    svg {
      color: theme.$close-icon-button-color-pressed;
    }
  }
}